module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"akabeko.me","short_name":"akabeko","start_url":"/","background_color":"#fbfcfa","theme_color":"#e74c3c","display":"minimal-ui","icons":[{"src":"favicon-48.png","sizes":"48x48","type":"image/png"},{"src":"favicon-72.png","sizes":"72x72","type":"image/png"},{"src":"favicon-96.png","sizes":"96x96","type":"image/png"},{"src":"favicon-144.png","sizes":"144x144","type":"image/png"},{"src":"favicon-192.png","sizes":"192x192","type":"image/png"},{"src":"favicon-256.png","sizes":"256x256","type":"image/png"},{"src":"favicon-384.png","sizes":"384x384","type":"image/png"},{"src":"favicon-512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
